<template>
    <div>
        <vx-card title="Shipment Cost">
            <!-- <div class="flex flex-col gap-5"> -->
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <span>Shipment Date</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <span>Driver</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <span>Vehicle</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <span>Type</span>
                    </div>
                </div>
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <div class="flex justify-between">
                            <date-range-picker
                                style="min-height: 40px"
                                class="w-full"
                                ref="picker"
                                opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                :singleDatePicker="false"
                                :timePicker="false"
                                :timePicker24Hour="false"
                                :showWeekNumbers="false"
                                :showDropdowns="false"
                                :autoApply="true"
                                v-model="shipmentDate"
                                :linkedCalendars="true"
                            >
                                <template v-slot:input="picker">
                                {{ globalDateFormat(picker.startDate) }} -
                                {{ globalDateFormat(picker.endDate) }}
                                </template>
                            </date-range-picker>
                            <vs-button
                                class="ml-4"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x"
                                @click="
                                () => {
                                    this.shipmentDate = { startDate: null, endDate: null };
                                }
                                "
                            ></vs-button>
                            <!-- <template>
                                <vx-tooltip text="reset">
                                    <vs-button
                                    type="line"
                                    icon-pack="feather"
                                    icon="icon-refresh-cw"
                                    @click.stop="handleResetInvoiceDateFilter()"
                                    />
                                </vx-tooltip>
                                </template> -->
                        </div>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <!-- <span>Driver</span> -->
                        <multiselect
                            class="selectExample"
                            v-model="selectFilterDriver"
                            :options="optionFilterDriver"
                            :multiple="true"
                            :allow-empty="true"
                            :group-select="false"
                            :max-height="160"
                            :limit="4"
                            placeholder="Type to search"
                            track-by="id"
                            label="name"
                            :disabled="false"
                        >
                            <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">
                                {{ props.option.name }}</span
                                >
                            </div>
                            </template>
                            <template slot="tag" slot-scope="props">
                            <div class="multiselect__tag">
                                <span>{{ props.option.name }}</span>
                                <i
                                class="multiselect__tag-icon"
                                @click.prevent
                                @mousedown.prevent.stop="props.remove(props.option, $event)"
                                />
                            </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <!-- <span>Vehicle</span> -->
                        <multiselect
                            class="selectExample"
                            v-model="selectFilterVehicle"
                            :options="optionFilterVehicle"
                            :multiple="true"
                            :allow-empty="true"
                            :group-select="false"
                            :max-height="160"
                            :limit="4"
                            placeholder="Type to search"
                            track-by="id"
                            label="number"
                            :disabled="false"
                        >
                            <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }} - {{ props.option.number }}</span>
                            </div>
                            </template>
                            <template slot="tag" slot-scope="props">
                            <div class="multiselect__tag">
                                <span>{{ props.option.name }} - {{ props.option.number }}</span>
                                <i
                                class="multiselect__tag-icon"
                                @click.prevent
                                @mousedown.prevent.stop="props.remove(props.option, $event)"
                                />
                            </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full whitespace-no-wrap">
                        <!-- <span>Type</span> -->
                        <multiselect
                            class="selectExample"
                            v-model="selectFilterType"
                            :options="optionFilterType"
                            :multiple="true"
                            :allow-empty="true"
                            :group-select="false"
                            :max-height="160"
                            :limit="4"
                            placeholder="Type to search"
                            track-by="name"
                            label="name"
                            :disabled="false"
                        >
                            <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                            </template>
                            <template slot="tag" slot-scope="props">
                            <div class="multiselect__tag">
                                <span>{{ props.option.name }}</span>
                                <i
                                class="multiselect__tag-icon"
                                @click.prevent
                                @mousedown.prevent.stop="props.remove(props.option, $event)"
                                />
                            </div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <vs-tabs :color="colorx" v-model="tabs" style="margin-top: 15px;">
                    <vs-tab @click="colorx = 'danger'" label="Open">
                        <div class="con-tab-ejemplo">
                            <open :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Waiting Approval">
                        <div class="con-tab-ejemplo">
                            <waiting :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Inquiry">
                        <div class="con-tab-ejemplo">
                            <inquiry :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Advance Request">
                        <div class="con-tab-ejemplo">
                            <advanceRequest :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Settlement">
                        <div class="con-tab-ejemplo">
                            <settlement :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Settled">
                        <div class="con-tab-ejemplo">
                            <settled :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Closed">
                        <div class="con-tab-ejemplo">
                            <closed :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Reject">
                        <div class="con-tab-ejemplo">
                            <reject :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                    <vs-tab @click="colorx = 'danger'" label="Cancel">
                        <div class="con-tab-ejemplo">
                            <cancel :shipmentDate="shipmentDate" :filterDriver="selectFilterDriver" :filterVehicle="selectFilterVehicle" :filterType="selectFilterType"/>
                        </div>
                    </vs-tab>
                </vs-tabs>
            <!-- </div> -->
        </vx-card>
    </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import open from "./open/open.vue";
import confirm from "./confirm/confirm.vue";
import cancel from "./cancel/cancel.vue";
import waiting from "./waiting/waiting.vue";
import advanceRequest from "./advance-request/advance-request.vue";
import settlement from "./settlement/settlement.vue";
import settled from "./settled/settled.vue";
import closed from "./closed/closed.vue";
import reject from "./reject/reject.vue";
import inquiry from "./inquiry/inquiry.vue";
// import done from "./done.vue";

export default {
    components: {
        open,
        confirm,
        cancel,
        settlement,
        waiting,
        advanceRequest,
        settled,
        closed,
        reject,
        inquiry,
        DateRangePicker,
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
        optionFilterDriver: [],
        optionFilterVehicle: [],
        selectFilterDriver: [],
        selectFilterVehicle: [],
        optionFilterType: [
            {
                name: "Internal",
            },
            {
                name: "Oncall"
            }
        ],
        selectFilterType: [],
        shipmentDate: {
            startDate: null,
            endDate: null,
        },
    }),
    methods: {
        getDrivers() {
			const params = {
				order: "name",
                sort: "asc"
			}
			console.log(params)
			this.$http
				.get("api/v1/master/drivers", {
					params: params
				})
				.then((resp) => {
					if (resp.code == 200) {
                        // console.log("JALAN");
                        this.optionFilterDriver = resp.data.records 
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Driver option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
        getVehicles() {
			const params = {
				order: "name",
                sort: "asc",
                ownership: "Internal"
			}
			console.log(params)
			this.$http
				.get("api/v1/master/vehicles", {
					params: params
				})
				.then((resp) => {
					if (resp.code == 200) {
                        // console.log("JALAN");
                        this.optionFilterVehicle = resp.data.records
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get vehicles option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
    },
    mounted() {
        this.getDrivers();
        this.getVehicles();
    }
}
</script>