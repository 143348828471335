<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}

.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Create Advance Cash</h4>
    </span>

    <div class="vx-row mb-2">
      <div class="vx-col w-full mb-2">
        <label class="vs-input--label">Tranport Plan</label>

        <multiselect
          class="selectExample"
          v-model="create.transport_plan"
          :options="option.transport_plan"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :internal-search="false"
          placeholder=" Type to search"
          track-by="id"
          label="shipment_number"
          @search-change="getOptionInvoice"
          @select="selectedInvoice"
        >
        </multiselect>
      </div>
      <div v-if="!globalIsEmptyObject(create.transport_plan)" class="vx-col w-full">
        <vs-card>
          <div slot="header">
            <div class="vx-row" style="padding-left: 10px">
              <div style="width: 80%">
                <h6 style="left-margin: 1%">
                  {{ create.transport_plan.shipment_number }}
                </h6>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              Shipment Number : {{ create.transport_plan.shipment_number }}<br />
              Shipment Plan Date : {{ globalDateFormat(create.transport_plan.delivery_plan_date) }}<br />
              Count DO/Pickup : {{ create.transport_plan.count_do_pickup
              }}<br />
              Estimated Stop : {{ create.transport_plan.estimated_stop
              }}<br />
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              Driver Name : {{ create.transport_plan.driver_name }}<br />
              Vehicle Name : {{ create.transport_plan.vehicle_name }}<br />
              Vehicle Number : {{ create.transport_plan.vehicle_number
              }}<br />
            </div>
            <!-- {{ create.invoice }} -->
          </div>
        </vs-card>
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%">Advance Cash</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          class="w-full input-readonly"
          label="Advance Cash Doc"
          name="Advance Cash Doc"
          v-model="create.controlValue"
          readonly
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Advance Cash Date</label>
        <datepicker
          :class="{
            'w-full': true,
            'border-danger': errors.has('postingDate'),
          }"
          v-model="create.postingDate"
          v-validate="'required'"
          name="postingDate"
          placeholder="Posting Date"
          :disabled-dates="{ from: new Date() }"
          :danger="errors.has('postingDate')"
        >
        </datepicker>
        <!-- display: block -->
        <span
          v-show="errors.has('postingDate')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("postingDate") }}
        </span>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Advance Cash Date</label>
        <datepicker
          :class="{
            'w-full': true,
            'border-danger': errors.has('postingDate'),
          }"
          v-model="create.postingDate"
          v-validate="'required'"
          name="postingDate"
          placeholder="Posting Date"
          :disabled-dates="{ from: new Date() }"
          :danger="errors.has('postingDate')"
        >
        </datepicker>
        <!-- display: block -->
        <span
          v-show="errors.has('postingDate')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("postingDate") }}
        </span>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          name="adjustmentValue"
          v-model="create.adjustmentValue"
          v-validate="`required|between:0,${maxAdjustment}`"
          class="w-full"
          @keypress="globalIsNumber($event)"
          @keyup="
            create.adjustmentValue = formatPrice(
              create.adjustmentValue.toString()
            )
          "
          label="Advance Cash Value"
          placeholder="Advance Cash Value"
          :danger="errors.has('adjustmentValue')"
        />
        <!-- display: block -->
        <span
          v-show="errors.has('adjustmentValue')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("adjustmentValue") }}
        </span>
      </div>
    </div>
    

    <div class="vx-col w-full" style="height: 100px;">
      <br />
      <!-- display: block -->
      <vs-button class="mb-2 mt-10" v-on:click="handleSubmit">
        Create Payment
      </vs-button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
// import { Validator } from 'vee-validate';

export default {
    components: {
        Datepicker,
    },
    created() { },
    data() {
        return this.initialState();
    },
    methods: {
        selectedInvoice(arg) {
            let remaining = parseFloat(arg.Remaining).toFixed(2)
            this.maxAdjustment = remaining
        },
        initialState() {
            return {
                fileAttachment: [],
                search: "",
                maxAdjustment: 0, 
                option: {
                    transport_plan: [],
                    coa: [],
                    costCenter: [],
                },
                create: {
                    transport_plan: {},
                    adjustmentValue: null,
                    postingDate: null,
                    coa: null,
                    reason: "",
                    costCenter: null,
                    controlValue: null,
                },
                customNotif: {
                    custom: {
                        adjustmentValue: {
                            required: "Adjustment value is empty",
                            between: (field, max) => `Adjustment value field must be between 0 and ${max.toString().split(",")[1]}`
                        },
                        reason: {
                            required: () => "Reason is empty",
                        },
                        costCenter: {
                            required: "Coct center value is empty",
                        },
                        postingDate: {
                            required: "Posting date value is empty",
                        },
                    },
                },
            };
        },
        mappingAttachment(){
          this.adjustment.Attachment.map(v => {
            this.fileAttachment.push({
              ListID: v.ListID,
              NameFile: v.NameFile,
              PathFile: v.PathFile,
            });
          })
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                // this.fileAttachment = [];
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    console.log(this.file[i]);
                    // this.uploadData(i);
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        formatPrice(angka, prefix = "") {
            return angka
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        paramUpload(array) {
            var form = new FormData();
            form.append("id", this.selected.ID);
            form.append("file", this.file[array]);
            return form;
        },
        uploadAttachmentAfter(i, id) {
            if (this.fileAttachment.length == 0) {
                this.$emit("closeDetail", true);
                this.$vs.loading.close();
                return;
            }
            if (this.fileAttachment.length < i) {
                this.$emit("closeDetail", true);
                this.$vs.loading.close();
                return;
            }
            var form = new FormData();
            form.append("id", id);
            form.append("file", this.fileAttachment[i].File);
            this.$http
                .post("/api/v1/finance/adjustment/upload-file", form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    this.uploadAttachmentAfter(++i, id);
                    // this.$vs.loading.close();
                })
                .catch((error) => {
                    this.uploadAttachmentAfter(++i, id);
                });
            // })
        },
        handleShow(file) {
            let objectURL;
            console.log(file);
            objectURL = URL.createObjectURL(file.File);

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleDeleteAttachment(id) {
            console.log(id);
            this.$vs.loading();
            var form = new FormData();
            form.append("list_id", id);
            form.append("id", this.selected.ID);
            this.$http
              .post("/api/v1/finance/adjustment/delete-file", form, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                  this.fileAttachment = this.fileAttachment.filter((v) => {
                    return v.ListID != id;
                  });
                  this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                } else {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              })
              .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
              });
        },
        handleSubmit() {
            this.$validator.validateAll().then((result) => {
                console.log(result);
                if (result) {
                    this.$vs.loading();
                    // console.log(this.create.optionBankSupplier);
                    const params = {
                        posting_date: moment(
                            this.create.posting_date == "" ? new Date() : this.create.postingDate
                        ),
                        invoice: this.create.invoice,
                        adjustment_value: parseFloat(this.create.adjustmentValue.toString().replace(/,/g, "")),
                        coa: this.create.coa,
                        transaction_type: '01',
                        source_of: 0,
                        reason: this.create.reason,
                        cost_center: this.create.costCenter,
                        control_value: parseFloat(this.create.controlValue.toString().replace(/,/g, "")),
                    };
                    console.log(params);
                    this.$http
                        .post("/api/v1/finance/adjustment/store", params)
                        .then((result) => {
                            this.$vs.loading.close();
                            if (result.code == 200) {
                                // this.handleClose()
                                this.$vs.notify({
                                    title: "Success",
                                    text: "",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                                this.uploadAttachmentAfter(0, result.data.AdjustmentFinance.ID);
                            } else {
                                this.handleClose();
                                this.$vs.notify({
                                    title: "Error",
                                    text: result.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                            }
                        })
                        .catch((e) => { });
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: this.errors.all().join("\n"),
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                    });
                }
            }); 
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("closeDetail", true);
        },
        getOptionInvoice(query) {
            if (query.length <= 2) {
                return;
            }
            this.$vs.loading();
            this.$http
                .get("api/v1/uang-jalan/transport-plan", {
                    params: {
                        search: query,
                        length: 10,
                        page: 1,
                        order: "asc",
                        sort: "code",
                        tax_type_id: 2,
                        // territory_id: this.selected.territory.ID,
                        status: 3,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.option.transport_plan = resp.data;
                        console.log("this.option.transport_plan", this.option.transport_plan);
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Transport Plan option",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    this.$vs.loading.close();
                });
        },
        onChangeCoa() {
        if (this.create.coa.Code.slice(0,1) == "7") {
          console.log(this.option.costCenter);

          let costCenter = this.option.costCenter.filter( v => v.Code == "OTH" )
          console.log(costCenter, "OTH");

          this.create.costCenter = costCenter[0]
        }
      }
    },
    mounted() {
        this.$validator.localize("en", this.customNotif);
        // this.getGeneralForm();
    },
    computed: {},
    watch: {
        "create.adjustmentValue"() {
            let adjustment = this.create.adjustmentValue.toString().replace(/[^.\d]/g, "").toString()
            let controlValue = this.maxAdjustment - adjustment
            // this.create.controlValue = parseFloat(controlValue.toString()).toFixed(2)
            this.create.controlValue = this.formatPrice(parseFloat(controlValue.toString()).toFixed(2))
        },
    },
};
</script>
<style scoped>
.pagination {
  display: flex;

  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.border-danger {
  border: 1px solid rgba(var(--vs-danger), 10) !important;

  border-radius: 5px;
}
</style>
